import React from 'react'
import { FaFacebook, FaInstagram } from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";

const Footer = () => {
  return (
    <FooterContainer>
        <FooterWrap>
            <SocialMedia>
              <SocialMediaWrap>
                <SocialLogo to=''>La Hacienda San Rafael</SocialLogo>
                <SocialIcons>
                    <SocialIconLink href='https://www.facebook.com/lahaciendasanrafael' target='_blank' aria-label="Facebook" rel='noopener noreferrer'>
                        <FaFacebook /> 
                    </SocialIconLink>
                    <SocialIconLink href='https://www.instagram.com/lahaciendasanrafael' target='_blank' aria-label="Instagram" rel='noopener noreferrer' >
                        <FaInstagram /> 
                    </SocialIconLink>
                </SocialIcons>
              </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer