import React from 'react';
import coupon1 from '../../images/lh-coupon-1.png'
import coupon2 from '../../images/lh-coupon-2.png'

import './CouponsDisplay.css'

const CouponsDisplay = () => {
    return (
        <div className="coupons">
            <h1 className="heading">Coupons</h1>
            <div className="imagesContainer">
                <img 
                    src={coupon1} 
                    alt="Coupon 1" 
                    className="image"
                />
                <img 
                    src={coupon2} 
                    alt="Coupon 2" 
                    className="image"
                />
            </div>
        </div>
    );
};


export default CouponsDisplay;
