const menuItems = {
    Appetizers: [
        { name: 'Super Nachos', price: '11.99', description: 'Tortilla chips, refried beans, melted jack cheese, pico de gallo, guacamole and sour cream. (Add Meat $3.00)'},
        { name: 'Taquitos Rancheros', price: '12.99', description: 'Deep fried rolled flour tortilla with choice of meat, lettuce sour cream, guacamole & Cotija cheese.'},
        { name: 'Chicken Wings', price: '11.99', description: 'ranch dressing on the side'},
        { name: 'Guacamole Dip', price: '10.45', description: 'daily fresh guacamole, topped with tomatoes, onions, cilantro & cotija cheese'},
        { name: 'Chorizon con Queso', price: '12.99', description: 'daily fresh guacamole, topped with tomatoes, onions, cilantro & cotija cheese'},
        { name: 'Bocadillo Sampler', price: '19.75', description: 'super nachos, taquitos rancheros, chicken wings & cheese quesadilla'},
    ],
    Quesadillas: [
        { name: 'Quesadilla de Maiz', price: '7.25', description: 'two corn tortilla with melted Jack cheese'},
        { name: 'Quesadilla Suiza', price: '10.99', description: 'flour tortilla with melted jack cheese topped with guacamole'},
        { name: 'Super Quesadilla', price: '13.99', description: 'Flour tortilla with melted Jack cheese, sour cream, guacamole & choice of meat. (Add Prawns $3.00).'},
    ],
    Desayuno: [
        { name: 'Huevos Rancheros', price: '$13.99', description: 'two over easy eggs on corn tortilla with salsa'},
        { name: 'Huevos con Chorizo', price: '$14.99', description: 'scrambled eggs with mexican sausage'},
        { name: 'Huevos a la Mexicana', price: '$13.99', description: 'scrambled eggs with pico de gallo'},
        { name: 'Huevos Divorciados', price: '$13.99', description: 'Two over easy eggs, one with green salsa & one with red salsa'},
        { name: 'Huevos Vaqueros', price: '$19.80', description: 'Two over easy eggs with carne asada (skirt steak)'},
        { name: 'Machaca con Huevos', price: '$14.99', description: 'Scrambled eggs shredded beef, bell pepper and onions'},
        { name: 'Chilaquiles con Huevos', price: '$14.99', description: 'Tortilla chips, scrambled eggs with red o green sauce on top, cotija cheese, cilantro & onion'},
        { name: 'Breakfast Burrito', price: '$12.25', description: 'Rice, refried beans, eggs, cheese and chorizo'},
    ],
    Salads: [
        { name: 'Taco Salad', price: '$14.99', description: 'crispy flour shell, choice of meat, refried beans, lettuce, guacamole, sour cream, tomatoes, cheese & mild sauce.'},
        { name: 'House Salad', price: '$9.95', description: 'romaine lettuce, avocados, tomatoes, onions, bell peppers & cotija cheese'},
        { name: 'Fiesta Salad', price: '$14.99', description: 'chicken breast, romaine lettuce, guacamole, sour cream, tortilla strips & cotija cheese'},
        { name: 'Chicken Al Pastor Salad', price: '$14.99', description: 'BBQ chicken breast, romaine lettuce, avocados, tomatoes, roasted pineapple chunks & cotija cheese'},
    ],
    Soups: [
        { name: 'Chicken Tortilla Soup', price: '', description: 'Tortilla strips, avocado, sour cream, fresh cheese, onions and cilantro (Med $11.49) (Lg. $13.99'},
        { name: 'Caldo de Pollo', price: '$14.99', description: 'chicken soup with vegetables, served with corn or flour tortillas'},
        { name: 'Menudo', price: '$15.50', description: 'Saturday & Sunday only'},
    ],
    Tacos: [
        { name: 'Choices of Meat', price: '', description: 'Carne Asada | Ground Beef | Shredded Beef | Shredded Chicken | Grilled Chicken | Al Pastor | Carnitas | Chorizo | Veggies '},
        { name: '1 Taco', price: '$4.25', description: 'choice of meat, with cilantro, onions, and salsa. If cripsy taco served with lettuce, tomatoes, cheese, and mild salsa.'},
        { name: 'Taco de Lengua', price: '$4.95', description: 'cilantro, onions, green salsa'},
        { name: 'Fish Taco', price: '$4.95', description: ''},
        { name: 'Shrimp Taco', price: '$4.95', description: ''},
        { name: 'Super Taco', price: '$6.65', description: 'choice of meat, lettuce, jack cheese, guacamole, sour cream, tomatoes, and mild sauce'},
        { name: 'Baja Taco', price: '$6.65', description: 'breaded cod fish or shrimp with chipotle sauce, tomatoes, and lettuce'},
    ],
    Burritos: [
        { name: 'Choices of Meat', price: '', description: 'Carne Asada | Ground Beef | Shredded Beef | Shredded Chicken | Grilled Chicken | Al Pastor | Carnitas | Chorizo | Veggies '},
        { name: '', price: '', description: 'add $1 for whole wheat, chipotle, spinach tortilla | black beans'},
        { name: 'Regular Burrito', price: '$12.00', description: 'choice of meat, whole beans, rice, topped with salsa (Prawns or Fish Add $3.00)'},
        { name: 'Super Burrito', price: '$13.00', description: 'choice of meat, whole beans, rice, jack cheese, sour cream and guacamole. Topped with salsa (Prawns or Fish Add $3.00)'},
        { name: 'Macho Burrito', price: '$15.50', description: 'choice of meat, refried beans, rice, jack cheese, topped with guacamole, sour cream, cilantro, tomatoes, onions, and salsa (Prawns or Fish Add $3.00)'},
        { name: 'Vegetarian Burrito', price: '$12.00', description: 'rice, beans, cheese, lettuce, guacamole, sour cream and tomatoes. Topped with salsa'},
        { name: 'Burrito con Mole', price: '$12.00', description: 'choice of meat, mole, whole beans, rice, jack cheese, sour cream, guacamole, topped with mole salsa'},
    ],
    Chimichangas: [
        { name: 'Chimichanga', price: '', description: 'deep fried burrito, with cheese and meat inside, topped with guacamole, sour cream, tomatoes, cotija cheese, and mile sauce, served with rice and beans on the side'},
        { name: 'choice of meat', price: '$14.75', description: ''},
        { name: 'vegetarian', price: '$14.25', description: 'whole beans, rice, lettuce, and cheese inside'},
    ],
    Veggie_Menu: [
        { name: '', price: '', description: 'steamed fresh veggies (broccoli, cauliflower, zucchini, and carrots, black beans & rice)'},
        { name: 'Veggie Enchilada', price: '$13.99', description: 'topped with green sauce'},
        { name: 'Soft Veggie Taco', price: '$13.99', description: 'topped with tomatoes'},
        { name: 'Veggie Tostada', price: '$13.99', description: 'topped with lettuce and tomatoes'},
        { name: 'Steamed Veggie Hot Plate', price: '$14.99', description: 'topped with tomatoes'},
        { name: 'Veggie Burrito', price: '$11.99', description: 'topped with green sauce'},
    ],
    Homemade_Dishes: [
        { name: 'Sope', price: '$6.50', description: 'one thick com masa shell topped with refried beans, choice of meat, lettuce, tomatoes, cotija cheese, sour cream and green sauce'},
        { name: 'Gordita', price: '$6.50', description: 'one thick corn masa pocket stuffed with choice of meat, lettuce, tomatoes, cotija cheese, sour cream and green sauce'},
        { name: 'Huarache', price: '$12.95', description: 'one long thick corn masa shell topped with refried beans choice of meat lettuce, tomatoes, cotija cheese sour cream and green sauce'},
    ],
    Tortas: [
        { name: 'Hot Mexican Sandwich', price: '$12.99', description: 'Hot Mexican sandwich with choice of meat, mayonnaise, avocado, lettuce, tomatoes, grilled onions, jalapenos and Jack cheese'},
    ],
    House_Combinations: [
        { name: '', price: '', description: 'choice of meat, served wit rice, refried beans, and salad (Add black beans $1.00)'},
        { name: 'One Enchilada', price: '$13.99', description: ''},
        { name: 'One Taco', price: '$13.99', description: ''},
        { name: 'One Chile Relleno', price: '$14.25', description: ''},
        { name: 'One Tostada', price: '$14.99', description: ''},
        { name: 'Two Enchiladas', price: '$16.99', description: ''},
        { name: 'Enchilada & Chile Relleno', price: '$17.99', description: ''},
        { name: 'Enchilada & Taco', price: '$16.99', description: ''},
        { name: 'One Taco & Chile Relleno', price: '$17.99', description: ''},
        { name: 'Two Tacos', price: '$16.99', description: ''},
        { name: 'Enchilada & Tostada', price: '$17.75', description: ''},
        { name: 'Enchilada & Tamal', price: '$16.99', description: ''},
        { name: 'Chile Relleno & Tamal', price: '$18.25', description: ''},
        { name: 'Two Chile Rellenos', price: '$20.50', description: ''},
        { name: 'Two Flautas', price: '$16.99', description: 'with sour cream and guacamole'},
        { name: 'Choices of Meat', price: '', description: 'Carne Asada | Ground Beef | Shredded Beef | Shredded Chicken | Grilled Chicken | Al Pastor | Carnitas | Chile Verde | Veggies '},
    ],
    Side_Orders: [
        { name: 'Beans', price: '$4.95', description: ''},
        { name: 'Rice', price: '$4.95', description: ''},
        { name: 'Rice and Beans', price: '$8.50', description: ''},
        { name: 'Tamal', price: '$6.95', description: ''},
        { name: 'Chile Relleno', price: '$7.95', description: ''},
        { name: 'Tortillas', price: '$2.50', description: 'corn or flour'},
        { name: 'Enchilada', price: '$6.50', description: ''},
        { name: 'Sour Cream', price: '$2.50', description: ''},
        { name: 'Cheese', price: '$1.50', description: ''},
        { name: 'Salsa', price: '$1.50', description: ''},
    ],
    Sizzling_Fajitas: [
        { name: '', price: '', description: 'sizzling fajita plate with rice, beans (pinto, refried, black beans) with corn or flour tortillas and lettuce guacamole and tomatoes'},
        { name: 'Chicken Fajitas', price: '$21.95', description: ''},
        { name: 'Steak Fajitas', price: '$22.95', description: ''},
        { name: 'Fish Fajitas', price: '$23.95', description: ''},
        { name: 'Prawn Fajitas', price: '$23.95', description: ''},
        { name: 'Mixed Fajitas', price: '$24.95', description: 'Chicken and Beef'},
        { name: 'Trio Fajitas', price: '$26.95', description: 'Chicken, Beef, and Shrimp'},
    ],
    Seafood: [
        { name: '', price: '', description: 'The following plates are served with rice,  beans (whole, black or refried), corn or flour tortillas'},
        { name: 'Mojarra Frita', price: '$22.95', description: 'Whole fried tilapia fish'},
        { name: 'Filete de Pescado', price: '$21.95', description: 'basa fish filet'},
        { name: 'Filete Empanizado', price: '$22.95', description: 'breaded basa fish filet'},
        { name: 'Camarones a la Hacienda', price: '$22.95', description: 'prawns sautéed w/ bell peppers, onions, and mushrooms'},
        { name: 'Camarones a la Diabla', price: '$22.95', description: 'extra hot and spicy prawns'},
        { name: 'Camarones con Crema', price: '$22.95', description: 'prawns sautéed in creamy sauce, with mushrooms'},
        { name: 'Camarones al Mojo de Ajo', price: '$22.95', description: 'sautéed prawns in garlic sauce, with tomatoes and onions'},
        { name: 'Enchiladas de Camaron', price: '$21.95', description: 'sautéed shrimp enchiladas with green salsa.'},
    ],
    Tostadas_y_Cocteles: [
        { name: 'Tostada de Ceviche', price: '$8.50', description: 'lime marinated basa fish mixed with pico de gallo, avocado served on a crispy corn tostada'},
        { name: 'Tostada de Camaron', price: '$8.95', description: 'grilled shrimp mixed with pico de gallo, avocado served on a cripsy corn tostada'},
        { name: 'Coctel de Camaron', price: '$20.95', description: 'prawns cocktail served with ketchup, clamato juice, avocado, and pico de gallo'},
        { name: 'Campechana', price: '$20.95', description: 'shrimp and octopus cocktail served with ketchup, clamato juice, avocado, and pico de gallo'}
    ],
    Kids_Menu: [
        { name: 'Kids Menu', price: '$9.45', description: 'For kids 8 and under. Includes soft drink, bottled drinks not included'},
        { name: 'Burrito', price: '', description: 'beans, rice, and cheese'},
        { name: 'Quesdailla', price: '', description: 'flour tortilla nad cheese'},
        { name: 'Nachos', price: '', description: 'beans and cheese'},
        { name: 'Enchilada', price: '', description: 'choice of meat, rice and beans'},
        { name: 'Taco', price: '', description: 'choice of meat, rice and beans'},
        { name: 'Chicken Nuggets', price: '', description: ''},
        { name: 'Cheese Burger', price: '', description: ''},
    ],
    Dessert: [
        { name: 'Flan', price: '$5.50', description: ''},
        { name: 'Churros', price: '$9.50', description: ''},
        { name: 'Sopapillas', price: '$9.50', description: ''},
        { name: 'Ice Cream', price: '$5.50', description: ''},
    ],
    Drinks: [
        { name: 'Soft Drinks', price: '$3.75', description: 'Coke | Sprite | Diet Coke | Pibb Ex | Root Beer | Orange Fanta | Lemonade | Iced Tea'},
        { name: 'Mexican Sodas', price: '$3.75', description: 'Mexican Coke | Sangria Senorial | Lime Jarritos | Mandarin Jarritos | Strawberry Jarritos | Tamarindo Jarritos | Mineral Water Jarritos '},
        { name: 'Aguas Frescas', price: '$3.75', description: 'Horchata | Jamaica | Tamarindo'},
        { name: 'Coffee', price: '$3.50', description: ''},
        { name: 'Apple or Orange Juice', price: '$3.50', description: ''},
    ],
    Beer: [
        { name: 'Bottled Beer', price: '$6.50', description: 'Corona | Pacifico | Modelo Especial | Negra Modelo | Bohemia | Victoria | XX Amber | XX Lager | Heineken | Heineken 0.0'},
        { name: 'Draft Beer', price: '$7.00', description: 'Lagunitas IPA | Lagunitas Lil Sumpin | Negra Modelo | Modelo Especial | XX Amber | Pacifico | Coors Light | Corona'},
        { name: 'Make it a Michelada', price: '+ $3.00', description: 'Selection of beer + spicy Clamato Mix'},
        { name: 'Make it a Chelada', price: '+ $2.00', description: 'Selection of beer + lime juice and salt'},
    ],
}

export default menuItems