import gallery1 from './images/gallery/gallery-1.jpg'
import gallery2 from './images/gallery/gallery-2.jpg'
import gallery3 from './images/gallery/gallery-3.jpg'
import gallery4 from './images/gallery/gallery-4.jpg'
import gallery5 from './images/gallery/gallery-5.jpg'
import gallery6 from './images/gallery/gallery-6.jpg'
import gallery8 from './images/gallery/gallery-8.jpg'
import gallery9 from './images/gallery/gallery-9.jpg'
import gallery10 from './images/gallery/gallery-10.jpg'
import gallery11 from './images/gallery/gallery-11.jpg'

export default [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, gallery8, gallery9, gallery10, gallery11]