import { motion } from 'framer-motion';
import { useRef, useEffect, useState } from "react";
import gallery from "../../gallery";
import './PhotoCarousel.css';


function PhotoCarousel() {
    const [width, setWidth ] = useState(0);
    const photoCarousel = useRef();

    useEffect(() => {
        setWidth(photoCarousel.current.scrollWidth - photoCarousel.current.offsetWidth)
    }, []);

    return (
        <motion.div ref={photoCarousel} className='photoCarousel' whileTap={{cursor: "grabbing"}}>
            <motion.div 
                drag="x" 
                dragConstraints={{ right: 0, left: -width }} 
                onPointerDown={(e) => e.currentTarget.setPointerCapture(e.pointerId)}
                className='inner-carousel'
                >
                {gallery.map((image) => {
                    return (
                    <motion.div className='item' key={image}>
                        <img src={image} alt="gallery of restaurant" />
                    </motion.div>
                    );
                })}
            </motion.div>
        </motion.div>

        
    )
}

export default PhotoCarousel;