import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GlobalStyle } from "./globalStyles";
import Hero from "./components/Hero";
import Feature from "./components/Feature";
import CarouselMenu from "./components/CarouselMenu";
import Footer from "./components/Footer";
import PhotoCarousel from "./components/PhotoCarousel/PhotoCarousel";
import CouponsDisplay from './components/CouponsDisplay/CouponsDisplay';

// Accessibility 
import { AccessibilityProvider } from "./components/AccessibilityContext/AccessibilityContext";
import AccessibilityButton from "./components/AccessibilityButton/AccessibilityButton";


function App() {
  return (
    <AccessibilityProvider>  {/* Ensure all components inside are wrapped by AccessibilityProvider */}
      <Router>
        <GlobalStyle />
        <div style={{ filter: 'var(--grayscale-filter)', fontSize: 'var(--font-size)' }}> {/* Use CSS variables for consistent styling */}
          <AccessibilityButton />
          <Hero />
          <PhotoCarousel />
          <Feature />
          <CarouselMenu />
          <CouponsDisplay />
          <Footer />
        </div>
      </Router>
    </AccessibilityProvider>
  );
}

export default App;
