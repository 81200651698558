import React from "react";
import { Bars, Nav, NavIcon, NavLink } from "./NavbarElements";

import logo from '../../images/la-hacienda-logo.png'

const Navbar = ({ toggle }) => {
    return (
        <>
            <Nav>
                <NavLink to='/'>
                    <img src={logo} alt='la hacienda san rafael logo' style={{ width: '200px', height: 'auto', padding: '20px', marginRight: '10px' }} />
                </NavLink>
                <NavIcon onClick={ toggle }>
                    <p>Menu</p>
                    <Bars />
                </NavIcon>
            </Nav>
        </>
    )
}

export default Navbar